require("./src/pages/index.css")
require("leaflet/dist/leaflet.css")


exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // See: https://www.gatsbyjs.org/packages/gatsby-background-image/
  if (typeof window.IntersectionObserver === `undefined`) {
    require("intersection-observer")
  }
}
